import { store } from "../store";

export const routes = [
  {
    path: "/",
    component: () => import("../pages/Layout.vue"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import("../pages/Home.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/home/:page?",
        name: "Home.page",
        onePage: true,
        component: () => import("../pages/Home.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/trabalhe-conosco",
        name: "TrabalheConosco",
        component: () => import("../pages/trabalheConosco/TrabalheConosco.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/blog",
        name: "Blog",
        component: () => import("../pages/blog/Blog.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/politica-privacidade",
        name: "PoliticaPrivacidade",
        component: () =>
          import("../pages/politicaPrivacidade/PoliticaDePrivacidade.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/ccee",
        name: "ccee",
        component: () => import("../pages/ccee/CCEE.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/form-success",
        name: "formSuccess",
        component: () => import("../pages/FormSucesso.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/sobre-a-skopos",
        name: "redirect",
        beforeEnter: (to, from, next) => {
          next("/");
        },
      },
      {
        path: "/parceiros/:parceiro",
        name: "Parceiro",
        onePage: true,
        component: () => import("../pages/parceiros/Parceiro.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../pages/NotFound.vue"),
      },
    ],
  },
];
